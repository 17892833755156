import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import "./Navbar.scss";

import { CartIcon, HeartIcon } from "../icons/icons";

import Cart from "../Cart/Cart";
import Whishlist from "../Whishlist/Whishlist";
import Logo from "../../images/logo.png";

const Navbar = () => {
  const [openComponent, setOpenComponent] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef(null);
  const cartRef = useRef(null);
  const whishlistRef = useRef(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleComponent = (component) => {
    if (openComponent === component) {
      setOpenComponent(null);
    } else {
      setOpenComponent(component);
    }
  };

  const products = useSelector((state) => state.cart.products);
  const items = useSelector((state) => state.whishlist.items);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the open components and the navbar
      if (
        openComponent &&
        !navbarRef.current.contains(event.target) &&
        !cartRef.current?.contains(event.target) &&
        !whishlistRef.current?.contains(event.target)
      ) {
        setOpenComponent(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openComponent]);

  return (
    <div className="navbar" ref={navbarRef}>
      <div className="wrapper">
        <Link to="/" className="link">
          <img src={Logo} alt="" className="logo" />
        </Link>
        <ul className={isOpen ? "navLinks active" : "navLinks"}>
          <NavLink className="link" to="/" end>
            <li>Home</li>
          </NavLink>
          <NavLink className="link" to="/store">
            <li>Store</li>
          </NavLink>
          <NavLink className="link" to="/about">
            <li>About</li>
          </NavLink>
          <NavLink className="link" to="/news&events">
            <li>News & Events</li>
          </NavLink>
          <NavLink className="link" to="/contact">
            <li>Contact</li>
          </NavLink>
        </ul>
        <div className="options">
          <div className="open">
            <svg
              onClick={toggleMenu}
              className="w-6 h-6 text-gray-800 dark:text-white"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              fill="none"
              viewBox="0 0 24 24"
            >
              {!isOpen ? (
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-width="2"
                  d="M5 7h14M5 12h14M5 17h14"
                />
              ) : (
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18 17.94 6M18 18 6.06 6"
                />
              )}
            </svg>
          </div>
          <div
            className="icon-container"
            onClick={() => toggleComponent("whishlist")}
          >
            <div className="heartsvg">
              <HeartIcon />
            </div>
            <span className="whishlist-span">{items.length}</span>
          </div>
          <div
            className="icon-container"
            onClick={() => toggleComponent("cart")}
          >
            <div className="cartsvg">
              <CartIcon />
            </div>
            <span className="cart-span">{products.length}</span>
          </div>
        </div>
      </div>
      {openComponent === "cart" && (
        <div ref={cartRef}>
          <Cart />
        </div>
      )}
      {openComponent === "whishlist" && (
        <div ref={whishlistRef}>
          <Whishlist />
        </div>
      )}
    </div>
  );
};

export default Navbar;
