import React from 'react';
import './Footer.scss';

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='footer-content'>
            <span>&copy; 2024 Vsmart Fashion. All rights reserved.</span>
            <span className='credit'>
                Website created by <a href="https://kaveeshtennakoon.github.io/Protofolio/" target="_blank" rel="noopener noreferrer">Kaveesh Tennakoon</a>
            </span>
        </div>
    </footer>
  );
}

export default Footer;
