import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import image1 from '../../images/slider1.jpg';
import image2 from '../../images/slider2.jpg';
import image3 from '../../images/slider3.jpg';
import image4 from '../../images/slider4.jpg';

const slideImages = [
    {
        url: image1,
    },
    {
        url: image2,
    },
    {
        url: image3,
    },
    {
        url: image4,
    }
]

const slider = () => {

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 4000,
        arrows: false,
        fade: true,
    };

    return (
        <div className='slider-container' style={{ width: '100%' }}>
            <Slider {...settings}>
                {slideImages.map((image, index) => (
                    <div key={index}>
                        <div
                            style={{
                                width: '100%',
                                height: '65vh',
                                backgroundSize: 'cover',
                                backgroundPosition: image.url === image2 ? 'center' : 'top', 
                                backgroundRepeat: 'no-repeat',
                                marginBottom: '10px',
                                backgroundImage: `url(${image.url})`
                            }}
                        >
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default slider;
