import React from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Store from "./Pages/Store/Store";
import About from "./Pages/About/About";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Events from "./Pages/Events/Events";
import Error from "./Pages/Error/Error";
import Contact from "./Pages/Contact/Contact";
import Whatsapp from "./components/Whatsapp/Whatsapp";
import EventPages from "./Pages/EventPage/EventPages";
import ScrollToTop from "./utilities/ScrollToTop";
import './App.scss';

const Layout = () => {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Outlet />
      <Whatsapp />
      <Footer />
    </div>
  );
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/store',
        element: <Store />
      },
      {
        path: '/about',
        element: <About />
      },
      {
        path: '/news&events',
        element: <Events />
      },
      {
        path: 'contact',
        element: <Contact />
      },
      {
        path: '/eventpage/:id',
        element: <EventPages />
      },
      {
        path: '*',
        element: <Error />
      }
    ]
  },
]);

function App() {
  return (
    <div>  
      <RouterProvider router={router} />
    </div>
  );
};

export default App;
