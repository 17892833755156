import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './Cart.scss';
import { removeItemCart, resetCart } from '../../app/cartReducer';

const Cart = () => {
  const products = useSelector(state => state.cart.products);
  const dispatch = useDispatch();

  const totalPrice = () => {
    let total = 0;
    products.forEach((item) => total += (item.quantity * item.price));
    return total.toFixed(0);
  };

  return (
    <div className='cartList'>
      <h1>In Cart</h1>
      {products?.map(item => (
        <div className="item" key={`${item.id}-${item.size}`}>
          <img src={item.img} alt="" />
          <div className="detail">
            <h1>{item.title}</h1>
            <p>{item.quantity} x LKR {item.price}</p>
            <p>Size: {item.size}</p>
          </div>
          <span onClick={() => dispatch(removeItemCart({ id: item.id, size: item.size }))}>
            remove
          </span>
        </div>
      ))}
      <div className="totalprice">
        <span>Total</span>
        <span>LKR {totalPrice()}</span>
      </div>
      <button>Proceed to Checkout</button>
      <div className="reset" onClick={() => dispatch(resetCart())}>Reset Cart</div>
    </div>
  );
}

export default Cart;
