import React from 'react';

import './Whatsapp.scss';

const Whatsapp = () => {
  return (
    <div><a className="whatsapp" href="https://wa.me/+94760109100" target='_blank' rel="noopener noreferrer">
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="50" viewBox="0 0 92 92" width="50"><path d="m0 0h91.5618v91.5618h-91.5618z" fill="#00d95f"/><g fill="#fff"><path d="m22.8906 66.9763 3.2847-12.2132c-2.649-4.7576-3.4906-10.3094-2.3704-15.6366s4.1271-10.0723 8.4689-13.3648 9.7273-4.9114 15.1683-4.5597c5.4409.3518 10.5723 2.6506 14.4525 6.4746 3.8803 3.824 6.2491 8.9167 6.6718 14.3436.4226 5.4269-1.1291 10.8239-4.3706 15.2008s-7.9553 7.4399-13.2763 8.6271c-5.3211 1.1872-10.8925.4189-15.6919-2.1639zm12.9318-7.8624.763.4519c3.4763 2.0571 7.5366 2.9083 11.5482 2.4211 4.0115-.4872 7.7489-2.2856 10.6298-5.1148s4.7434-6.5304 5.2973-10.5267-.2319-8.0634-2.235-11.5673-5.1109-6.2479-8.8392-7.8043c-3.7283-1.5565-7.8675-1.8379-11.7728-.8006-3.9052 1.0374-7.357 3.3353-9.8175 6.5357s-3.7915 7.1235-3.7855 11.1579c-.0032 3.3452.9234 6.6256 2.6769 9.4762l.4785.7875-1.8363 6.8167z"/><path clip-rule="evenodd" d="m54.3404 47.0028c-.4473-.3601-.971-.6136-1.5313-.7412-.5602-.1275-1.1422-.1257-1.7017.0053-.8405.3486-1.3837 1.6655-1.9268 2.3239-.1145.1578-.2828.2685-.4733.3113s-.39.0147-.5612-.0789c-3.0774-1.2033-5.6568-3.41-7.3194-6.2615-.1419-.178-.209-.4039-.1873-.6303.0218-.2263.1306-.4355.3036-.5833.6058-.5987 1.0506-1.3403 1.2932-2.156.0539-.8999-.1526-1.7962-.5948-2.5821-.3419-1.1018-.9926-2.0829-1.8751-2.8274-.4552-.2044-.96-.2729-1.4533-.1973s-.9542.2921-1.3271.6234c-.6473.5575-1.161 1.2529-1.5034 2.0349-.3425.782-.5048 1.6307-.4751 2.4837.0019.479.0628.9559.181 1.4201.3003 1.1154.7621 2.1809 1.3708 3.1631.4391.7523.9183 1.4806 1.4354 2.1818 1.6805 2.3032 3.793 4.2587 6.2202 5.758 1.218.7619 2.5197 1.3814 3.8795 1.8462 1.4126.6393 2.9722.8846 4.5132.7101.878-.1327 1.7099-.4788 2.4225-1.0078.7126-.5291 1.284-1.2249 1.664-2.0262.2232-.484.291-1.0252.1939-1.5492-.2327-1.0716-1.6682-1.7042-2.5475-2.2206z" fill-rule="evenodd"/></g></svg>
    </a></div>
  )
}

export default Whatsapp