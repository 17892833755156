import React from "react";

import "./Branches.scss";
import {
  BuildingIcon,
  LocationIcon,
  PhoneIcon,
  MailIcon,
} from "../icons/icons.js";

const Branches = () => {
  return (
    <div className="branches">
      <h1>Our Branches</h1>

      <div className="branchArea">
        <div className="branchContainer">
          <div className="branchHeader">
            <BuildingIcon />
            <h2>Negombo</h2>
          </div>

          <div className="inlineC">
            <LocationIcon />
            <span>233/A Negombo - Giriulla Rd, Negombo</span>
          </div>
          <div className="inlineC">
            <PhoneIcon />
            <span>077 3168891</span>
          </div>

          <div className="inlineC">
            <MailIcon />
            <span>info@vsmartfashion.lk</span>
          </div>
        </div>

        <div className="branchContainer">
        <div className="branchHeader">
            <BuildingIcon />
            <h2>Chilaw</h2>
          </div>

          <div className="inlineC">
            <LocationIcon />
            <span>60, Colombo Road, Chilaw, Sri Lanka</span>
          </div>
          <div className="inlineC">
            <PhoneIcon />
            <span>076 0109101</span>
          </div>

          <div className="inlineC">
            <MailIcon />
            <span>info@vsmartfashion.lk</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Branches;
