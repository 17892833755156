import React from "react";
import "./Contact.scss";
import {
  PhoneIcon,
  MailIcon,
  FacebookIcon,
  InstaIcon,
  WhatsappIcon,
} from "../../components/icons/icons";

const Contact = () => {
  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", process.env.REACT_APP_EMAIL_API);

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: json,
      });
      const response = await res.json();
      if (response.success) {
        alert("Your message has been successfully sent!");

        event.target.reset();
      } else {
        alert("There was an error submitting your message. Please try again.");
      }
    } catch (error) {
      alert("Network error: Unable to send your message.");
    }
  };

  return (
    <div className="contact">
      <h1>Contact Us</h1>
      <div className="ContactInfo">
        <form onSubmit={onSubmit}>
          <div className="inputBox">
            <label htmlFor="">Full Name</label>
            <input
              type="text"
              className="field"
              placeholder="Enter your name"
              name="name"
              required
            />
          </div>
          <div className="inputBox">
            <label htmlFor="">Email Address</label>
            <input
              type="email"
              className="field"
              placeholder="Enter your email"
              name="email"
              required
            />
          </div>
          <div className="inputBox">
            <label htmlFor="">Phone Number</label>
            <input
              type="tel"
              className="field"
              placeholder="Enter your phone number"
              pattern="[0-9]{10}"
              name="phone"
              required
            />
          </div>
          <div className="inputBox">
            <label htmlFor="">Message</label>
            <textarea
              type="email"
              className="field message"
              placeholder="Leave your message"
              name="message"
              required
            />
          </div>
          <button type="submit">Send Message</button>
        </form>

        <div className="ContactDetails">
          <h2>Get In Touch</h2>
          <div className="Info">
            <h3>Hotlines</h3>
            <span>
              <PhoneIcon /> 076 0109101 - Chilaw{" "}
            </span>
            <span>
              <PhoneIcon /> 077 3168891 - Negombo{" "}
            </span>
            <h3>Email</h3>
            <span>
              <MailIcon />
              info@vsmartfashion.lk
            </span>
            <h3>Social Media</h3>
            <div className="social">
              <a
                href="https://www.facebook.com/share/2t4d8Zbci2ScoEBW/?mibextid=LQQJ4d"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon width={40} />
              </a>
              <a
                href="https://www.instagram.com/vsmart_thefashiongallery?igsh=MWRjamVzcmRmNWwxNg%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstaIcon width={40} />
              </a>
              <a
                href="https://wa.me/+94742063132"
                target="_blank"
                rel="noopener noreferrer"
              >
                <WhatsappIcon width={40} />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="locationWrap">
        <h2>Locations</h2>
        <div className="location">
          <div className="locationArea">
            <div className="locationMap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.1117332611616!2d79.86541327432344!3d7.228094892777978!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2e949432f516b%3A0x78b1994c910b4bc4!2sVidumini%20Fashion!5e0!3m2!1sen!2slk!4v1725123207589!5m2!1sen!2slk"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Negombo"
              ></iframe>
            </div>
            <h4>Vidumini Fashion - Negombo</h4>
          </div>
          <div className="locationArea">
            <div className="locationMap">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3955.0163414479184!2d79.79441387432735!3d7.573196592441017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2c92ba289010b%3A0xfa5fd7b14e0f60a5!2sVsmart%20-%20The%20Fashion%20Gallery!5e0!3m2!1sen!2slk!4v1725123531239!5m2!1sen!2slk"
                width="600"
                height="450"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map Chilaw"
              ></iframe>
            </div>
            <h4>Vsmart Fashion - Chilaw</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
