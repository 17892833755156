import { createSlice } from '@reduxjs/toolkit';

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    products: []
  },
  reducers: {
    addToCart: (state, action) => {
      const { id, size } = action.payload;

      // Find item by id and size
      const item = state.products.find(item => item.id === id && item.size === size);

      if (item) {
        item.quantity += action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }
    },
    removeItemCart: (state, action) => {
      const { id, size } = action.payload;

      // Remove the specific item with matching id and size
      state.products = state.products.filter(item => !(item.id === id && item.size === size));
    },
    resetCart: (state) => {
      state.products = [];
    }
  }
});

export const { addToCart, removeItemCart, resetCart } = cartSlice.actions;

export default cartSlice.reducer;
