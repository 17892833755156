import React from "react";
import "./About.scss";
import Img1 from "../../images/vidumini2.jpeg";

import { FacebookIcon, InstaIcon, WhatsappIcon } from "../../components/icons/icons";

const VisionSection = () => {
  return (
    <div className="about">
      <div className="about-section">
  <div className="row">
    <div className="flex1">
      <h2>About Us</h2>
      <p>
        <span>Vidumini Fashion</span> began its story on <span>November 28th, 2008,</span> as a humble
        store located on Small Road. Founded by Nilantha Edirisinghe and
        Chamuthu Edirisinghe, a husband and wife team, the store quickly became
        a beloved destination for fashion enthusiasts in the area. As demand
        grew, Vidumini Fashion expanded to a three-story building on <span>December
        12th, 2012,</span> offering an even wider selection of styles and trends. Our
        commitment to quality and customer satisfaction has always been at the
        heart of our business. In 2024, Vidumini Fashion took another
        significant step forward by expanding to a new location in Chilaw. The
        grand opening of this showroom is set to be held on <span>September 6th, 2024.</span>
        We are excited to bring our unique blend of fashion and service to a new
        community, continuing our journey of growth and dedication to our
        customers.
      </p>
      <div className="social">
        <a
          href="https://www.facebook.com/share/2t4d8Zbci2ScoEBW/?mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon width={60} />
        </a>
        <a
          href="https://www.instagram.com/vsmart_thefashiongallery?igsh=MWRjamVzcmRmNWwxNg%3D%3D&utm_source=qr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstaIcon width={60} />
        </a>
        <a
          href="https://wa.me/+94742063132"
          target="_blank"
          rel="noopener noreferrer"
        >
          <WhatsappIcon  width={60}/>
        </a>
      </div>
    </div>
    <div className="flex2">
      <img src={Img1} alt="" />
    </div>
  </div>
</div>
      <div className="spacer layer1"></div>
      <div className="vision-sector">
        <div className="vision-content">
          <div className="left-column">
            <h2>Our Vision</h2>
            <p className="vision-statement">
              Vidumini Fashion aims to become the market leader and service
              leader in the fashion industry within the city by the dawn of
              2026.
            </p>
          </div>
          <div className="right-column">
            <div className="vision-items">
              <div className="vision-item">
                <div className="icon">👔</div>
                <div className="text">
                  <h3>Empower Youth</h3>
                  <p>
                    Create attractive and rewarding job opportunities for local
                    youth, developing them into competent professionals and
                    responsible citizens, while nurturing their charismatic
                    personalities to form the core of the highly efficient
                    Vidumini sales team.
                  </p>
                </div>
              </div>
              <div className="vision-item">
                <div className="icon">💡</div>
                <div className="text">
                  <h3>Customer Delight</h3>
                  <p>
                    Ensure customer satisfaction and foster loyalty by offering
                    appealing fashion at competitive prices, delivered with the
                    exceptional service of our friendly and professional sales
                    team.
                  </p>
                </div>
              </div>
              <div className="vision-item">
                <div className="icon">🌟</div>
                <div className="text">
                  <h3>Fashion Hub</h3>
                  <p>
                    Continuously introduce new fashion trends to establish
                    Vidumini Fashion as the city’s premier fashion hub, making
                    our brand the most sought-after trend among the younger
                    generation.
                  </p>
                </div>
              </div>
              <div className="vision-item">
                <div className="icon">🔍</div>
                <div className="text">
                  <h3>Trust and Transparency</h3>
                  <p>
                    Prioritize delight, transparency, and trustworthiness in
                    every transaction, ensuring a seamless experience for
                    customers, sales teams, supply chains, and regulatory
                    bodies.
                  </p>
                </div>
              </div>
              <div className="vision-item">
                <div className="icon">🎯</div>
                <div className="text">
                  <h3>Dedication to Excellence</h3>
                  <p>
                    Uphold the Vidumini Promise with unwavering dedication and
                    generous service, making these qualities the hallmark of the
                    Vidumini team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="spacer layer2"></div>
    </div>
  );
};

export default VisionSection;
