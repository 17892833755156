import React from 'react';
import './Events.scss';
import EventCard from '../../components/EventCard/EventCard';
import useFetch from '../../utilities/useFetch';

const Events = () => {
  const { data, loading, error } = useFetch(`/events?populate=*&sort[0]=createdAt:desc`);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Failed to fetch events.</p>;

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  return (
    <div className='events'>
      <h1>News & Events</h1>
      {data && data.length > 0 && (    
        <>
          <EventCard item={data[0]} key={data[0].id} />

          {chunkArray(data.slice(1), 3).map((chunk, index) => (
            <div className="row" key={index}>
              {chunk.map(item => (
                <div className="col" key={item.id}>
                  <EventCard item={item} className={"small"} />
                </div>
              ))}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

export default Events;
