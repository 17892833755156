import React from "react";
import "./EventCard.scss";
import { ArrowRight } from "../icons/icons";
import { Link } from "react-router-dom";

const EventCard = ({ item, className }) => {
  const truncateText = (text, limit) => {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };

  return (
    <div className="CardWrap">
      <div className={`EventCard ${className}`}>
        <div className="CardImgWrap">
          <div className="CardImg">
            <img
              src={
                process.env.REACT_APP_UPLOAD_URL +
                item.attributes?.Thumbnail.data.attributes.url
              }
              alt="Thumbnail of news & events"
            />
          </div>
        </div>
        <div className="CardContent">
          <div className="CardTitle">
            <h3>{truncateText(item?.attributes.Title, 12)}</h3>
          </div>
          <p className="CardSummary">
            {truncateText(item?.attributes.Summary, 40)}
          </p>
          <div className="CardButton">
            <Link className="link" to={`/eventpage/:${item.id}`}>
              <button>
                Read More <ArrowRight />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
