import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeItemList, resetList } from '../../app/whishlistReducer';

import './Whishlist.scss';

const Whishlist = () => {

  
  const whishlistItems = useSelector((state) => state.whishlist.items)
  const dispatch = useDispatch()
  

  return (
    <div className='Whishlist'>
      <h1>In WishList</h1>
      {whishlistItems?.map(item=>(
        <div className="item" key={item.id}>    
            <Link className='link' to={`/product/${item.id}`}>
                <img src={item.img} alt="" />
                <div className="detail">
                    <h1>{item.title}</h1>
                    <p>Rs.{item.price}</p>
                </div>
          <span onClick={()=>dispatch(removeItemList(item.id))}>remove</span>
          </Link>
        </div>
      ))}
      <div className="reset" onClick={()=>dispatch(resetList())}>Reset Whishlist</div>
    </div>
    
  )
}

export default Whishlist