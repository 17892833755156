import React from 'react';
import './Store.scss';

const Store = () => {
  return (
    <div className="store">
      <div className="middle">
        <h1>Online store will be opening soon</h1>
        <hr />
      </div>
    </div>
  );
};

export default Store;
