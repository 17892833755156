import React from 'react';
import { Link } from 'react-router-dom';
import './Error.scss';

const Error = () => {
  return (
    <div class="errorContainer">
         <h2 id="oops" inert>OOPS!</h2>
         <h4 id="error" inert>Error 404 : Page Not Found</h4>
         <Link to={'/'}>
         <button>Back Home</button>
         </Link>
    </div>
)
};

export default Error