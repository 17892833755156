import React from 'react';

import './home.scss';
import Slider from '../../components/Slider/Slider';
import Branches from '../../components/Branches/Branches';
import QuickLinks from '../../components/OuickLinks/OuickLinks';


const Home = () => {
  return (
    <div className="home">
      <Slider />
      <Branches/>
      <QuickLinks />
    </div>

  )
}

export default Home