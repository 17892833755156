import React from "react";
import { Link } from "react-router-dom";

import "./OuickLinks.scss";
import {
  FacebookIcon,
  InstaIcon,
  WhatsappIcon,
  PhoneIcon,
  MailIcon,
} from "../icons/icons";

const OuickLinks = () => {

  return (
    <div className="OuickLinks">
      <div className="linkContainer">
        <h3>Links</h3>
        <ul>
          <li>
            <Link className="link" to="/">
              Home
            </Link>
          </li>
          <li>
            <Link className="link" to="store">
              Store
            </Link>
          </li>
          <li>
            <Link className="link" to='/news&events'>News & Events</Link>
          </li>
        </ul>
      </div>

      <div className="linkContainer">
        <h3>Information</h3>
        <ul>
          <li>
            <Link className="link" to="/about">
              About
            </Link>
          </li>
          <li>
            <Link className="link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>

      <div className="linkContainer">
        <h3>Contact Us</h3>
        <div className="inlineC">
          <PhoneIcon /> <span>077 3168891</span>
        </div>
        <div className="inlineC">
          <PhoneIcon /> <span>076 0109101</span>
        </div>
        <div className="inlineC">
          <MailIcon /> <span>info@vsmartfashion.lk</span>
        </div>
      </div>

      <div className="linkContainer">
        <h3>Follow Us On</h3>
        <div className="social">
          <a
            href="https://www.facebook.com/share/2t4d8Zbci2ScoEBW/?mibextid=LQQJ4d"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://www.instagram.com/vsmart_thefashiongallery?igsh=MWRjamVzcmRmNWwxNg%3D%3D&utm_source=qr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstaIcon />
          </a>
          <a
            href="https://wa.me/+94760109100"
            target="_blank"
            rel="noopener noreferrer"
          >
            <WhatsappIcon />
          </a>
        </div>
      </div>

      <div className="linkContainer">
        <h3>Openning Hours</h3>
        <p>Mon-Sun : 09:00 - 21:00</p>
      </div>
    </div>
  );
};

export default OuickLinks;
