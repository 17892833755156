import { createSlice } from '@reduxjs/toolkit'

export const whishlistSlice = createSlice({
  name: 'whishlist',
  initialState: {
    items: []
  },
  reducers: {
    addToList: (state, action) => {
      const item = state.items.find(item=>item.id === action.payload.id)

      if (!item){
        state.items.push(action.payload)
      } 
    },
    removeItemList: (state,action) => {
      state.items = state.items.filter(item=>item.id !== action.payload)
    },
    resetList: (state) => {
      state.items = []
    }
  }
})

export const { addToList, removeItemList, resetList } = whishlistSlice.actions

export default whishlistSlice.reducer