import React from 'react';
import './EventPages.scss';
import { useParams } from 'react-router-dom';
import useFetch from '../../utilities/useFetch';

const EventPages = () => {
    const id = useParams().id.replace(':', '');
    const { data, loading, error } = useFetch(`/events/${id}?populate=*`);

    console.log(id);

    return (
        <div className='eventPage'>
            <h1>{data?.attributes.Title}</h1>
            <img src={process.env.REACT_APP_UPLOAD_URL + data?.attributes.Thumbnail.data.attributes.url} alt="" />
            <div className='Summary'>
                {data?.attributes.Summary.split('\n').map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
        </div>
    )
}

export default EventPages;